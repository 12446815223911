import { computed, ref } from 'vue';
import { isJSON } from '@shared/utils/helpers';

export function useConditionalValue(props: Record<string, any>) {
  function getDefaultText() {
    if (!props.element.conditionalValue) return '';
    if (!isJSON(props.element.conditionalValue)) return '';
    return (Object.values(
      JSON.parse(props.element.conditionalValue || '{}') || {}
    )[0] as any)?.value || '';
  }

  function parseConditionalValue(val = props.element.conditionalValue) {
    if (!isJSON(val)) return null;
    return val ? JSON.parse(val) : null;
  }

  function getActiveCondition() {
    if (props.isEditorMode) {
      return props.pageData?.activeCustomization?.value?.[props.element.id] || Object.keys(conditionalValues.value || {})[0];
    }

    return props.pageData?.getActiveCondition?.();
  }

  function getConditionalValue() {
    if (props.isEditorMode) {
      if (conditionalValues.value) {
        return defaultConditionalValue.value || props.value;
      } else {
        return props.value;
      }
    }
  
    return props.pageData?.applyConditionalValue?.(conditionalValues.value, props.value) || props.value;
  }

  const condition = computed(getActiveCondition);
  const defaultConditionalValue = ref(getDefaultText());
  const conditionalValues = computed(() => parseConditionalValue());
  const textValue = computed(() => getConditionalValue());

  return {
    textValue,
    condition,
    conditionalValues,
    defaultConditionalValue
  }
}